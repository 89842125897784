function handleUserSelectors() {
  const $form = $('#adminUserDetailsForm');
  const $userRoleInput = $('#user_role_ids');

  // IDs de roles
  const dataManagerRolId = $userRoleInput.data('managerId');
  const adminRolId = $userRoleInput.data('adminId');
  const executiveRoleId = $userRoleInput.data('executiveId'); // ID del rol de directivo

  // Selector de unidades académicas
  const $academicUnitSelector = $form.find('#user_academic_unit_ids');
  if (
      $userRoleInput.val().includes(dataManagerRolId.toString()) ||
      $userRoleInput.val().includes(adminRolId.toString())
  ) {
    $academicUnitSelector.removeAttr('disabled');
  } else {
    $academicUnitSelector.attr('disabled', true);
  }

  // Selector de unidades de directivo
  const $executiveUnitSelector = $form.find('#user_executive_units_id');
  if ($userRoleInput.val().includes(executiveRoleId.toString())) {
    $executiveUnitSelector.removeAttr('disabled');
  } else {
    $executiveUnitSelector.attr('disabled', true);
  }
}

export default function initUserDetailsForm() {
  const $userRoleInput = $('#user_role_ids');
  $userRoleInput.on('change', handleUserSelectors);
  if ($userRoleInput.length) {
    handleUserSelectors(); // Llama a la función al cargar la página
  }
}

function handleExecutiveUnitSelector() {
  const $executiveUnitSelector = $('#user_executive_units_id');

  // Obtener las facultades seleccionadas
  const selectedFaculties = $executiveUnitSelector
      .find('option.faculty-option:selected') // Opciones seleccionadas con la clase faculty-option
      .map((_, option) => $(option).data('faculty-id'))
      .toArray(); // Array de IDs de facultades seleccionadas

  // Habilitar/deshabilitar las unidades
  $executiveUnitSelector.find('option.unit-option').each((_, option) => {
    const $option = $(option);
    const facultyId = $option.data('faculty-id');

    if (selectedFaculties.includes(facultyId)) {
      $option.prop('disabled', true).prop('selected', false);
    } else {
      $option.prop('disabled', false);
    }
  });
}

$(document).ready(() => {
  handleExecutiveUnitSelector();
});
$(document).on('change', '#user_executive_units_id', handleExecutiveUnitSelector);


/**
 * File generated by js-routes 2.1.2
 * Based on Rails 6.1.7.8 routes of Fichaacademica::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /categorias-academicas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const academic_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"categorias-academicas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grados-academicos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const academic_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grados-academicos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /delegados/academicos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const academics_delegation_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"delegados"],[2,[7,"/"],[2,[6,"academicos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resumenes/:id/activar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_summary_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /active_summary_configurations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const active_summary_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"active_summary_configurations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grupos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grupos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grupos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grupos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /actividades/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /actividades(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_instances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tipos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tipos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tipos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_kinds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tipos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subtipos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subtipos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/delegados/academicos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_academics_delegation_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"delegados"],[2,[7,"/"],[2,[6,"academicos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/curriculums/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_curriculum_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/curriculums(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_curriculum_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"curriculums"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/resumenes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_summary_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/resumenes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_summary_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resumenes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ejecutar-tareas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_task_executions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ejecutar-tareas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/downloads/all_change_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_change_status_api_downloads_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[6,"all_change_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_instances/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_activity_instance_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_instances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_activity_template_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_activity_templates_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_visualization_cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_activity_visualization_card_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_visualization_cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_visualization_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_activity_visualization_cards_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_visualization_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/personal-information/address-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_address_data_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"personal-information"],[2,[7,"/"],[2,[6,"address-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/personal-information/contact-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contact_data_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"personal-information"],[2,[7,"/"],[2,[6,"contact-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/personal-information/contract-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_data_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"personal-information"],[2,[7,"/"],[2,[6,"contract-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/personal-information/decree-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_decree_data_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"personal-information"],[2,[7,"/"],[2,[6,"decree-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/personal-information/personal-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_personal_data_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"personal-information"],[2,[7,"/"],[2,[6,"personal-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/summary_configurations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_summary_configuration_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"summary_configurations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/summary_configurations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_summary_configurations_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"summary_configurations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/personal-information/uc-contact-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_uc_contact_data_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"personal-information"],[2,[7,"/"],[2,[6,"uc-contact-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_templates/:id/auto_cube_import(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auto_cube_import_api_activity_template_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"auto_cube_import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/backwards_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backwards_step_activity_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"backwards_step"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /taxonomia-base(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const base_taxonomy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"taxonomia-base"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/cargas-masivas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"cargas-masivas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/cargas-masivas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"cargas-masivas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ejecutar-tareas/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_admin_task_execution_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ejecutar-tareas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cas_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cas_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cas_login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /correction_requests/:id/close_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_request_correction_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"correction_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"close_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /correction_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const correction_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"correction_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /correction_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const correction_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"correction_requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /curriculums/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const curriculum_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /curriculums(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const curriculum_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"curriculums"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /actividades/:id/cv_discard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cv_discard_activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cv_discard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /actividades/:id/cv_undiscard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cv_undiscard_activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cv_undiscard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /data-manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_manager_home_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"data-manager"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /delegados/delegados(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delegates_delegation_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"delegados"],[2,[7,"/"],[2,[6,"delegados"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /delegados/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delegation_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"delegados"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /delegados(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delegation_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"delegados"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subtipos/:id/destroy_all_activities(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_all_activities_activity_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_all_activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /actividades/:id/destroy_urgent(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_urgent_activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_urgent"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /directivo_academicos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const directivo_academicos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"directivo_academicos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /curriculum-base(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_base_curriculum_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"curriculum-base"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /curriculums/download/:filename(.:format)
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_curriculum_configurations_path = __jsr.r({"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"filename"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /descargar-cv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_cv_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"descargar-cv"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/cargas-masivas/download_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_template_bulk_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"cargas-masivas"],[2,[7,"/"],[2,[6,"download_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/draft(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_instance_activity_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grados-academicos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_academic_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grados-academicos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /campos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_activity_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"campos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subtipos/:id/editar-campos(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_activity_fields_activity_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar-campos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /grupos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_activity_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grupos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tipos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_activity_kind_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tipos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subtipos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_activity_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_activity_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/curriculums/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_curriculum_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/resumenes/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_summary_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/cargas-masivas/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_bulk_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"cargas-masivas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /curriculums/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_curriculum_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /delegados/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_delegation_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"delegados"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operator/fields/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /grados-medicos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_health_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grados-medicos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /idiomas/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_language_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"idiomas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /perfeccionamiento-personal/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_personal_improvement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"perfeccionamiento-personal"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reconocimientos-personales/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_personal_recognition_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reconocimientos-personales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /postitulos/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_postgraduate_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"postitulos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /experiencias-previas/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_previous_experience_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"experiencias-previas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /titulos-profesionales/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_professional_title_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"titulos-profesionales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /becas/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_scholarship_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"becas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /redes-sociales/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_social_network_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"redes-sociales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /resumenes/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_summary_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tags/:id/editar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /actividades/:id/editar-etiquetas(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tags_activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar-etiquetas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/taxonomia/editar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_taxonomy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"taxonomia"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /educacion-y-perfeccionamiento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const education_and_improvement_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"educacion-y-perfeccionamiento"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /preguntas-frecuentes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const faqs_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"preguntas-frecuentes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/activity_instances/:id/feed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feed_api_activity_instance_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_instances"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"feed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /retroalimentacion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feedbacks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retroalimentacion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /operator/fields/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operator/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/:id/terminar-ingreso(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finish_activity_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"terminar-ingreso"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /curriculums/generate/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_curriculum_configurations_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[6,"generate"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /swagger
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grape_swagger_rails_path = __jsr.r({}, [2,[7,"/"],[6,"swagger"]]);

/**
 * Generates rails route to
 * /swagger/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grape_swagger_rails_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"swagger"]],[7,"/"]]);

/**
 * Generates rails route to
 * /guia_de_uso_pa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const guia_de_uso_pa_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"guia_de_uso_pa"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /grados-medicos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grados-medicos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grados-medicos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const health_degrees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grados-medicos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/activity_uploads/:id/history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_api_activity_upload_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /impersonar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"impersonar"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /instituciones/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const institution_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"instituciones"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /instituciones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const institutions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"instituciones"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /kinds_async(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kinds_async_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"kinds_async"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /idiomas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const language_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"idiomas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /idiomas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const languages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"idiomas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /loginas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loginas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"loginas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/usuarios/:id/loginas(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const loginas_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"usuarios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"loginas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subtipos/:id/editar-campos(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const migrate_field_activity_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar-campos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /subtipos/:id/editar-tarjetas(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_cards_activity_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar-tarjetas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /actividades/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_activity_instance_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/new_activity_instance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_activity_instance_activity_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"new_activity_instance"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subtipos/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_activity_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subtipos"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_activity_upload_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/curriculums/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_curriculum_configuration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/resumenes/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_summary_configuration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/cargas-masivas/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_bulk_upload_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[6,"cargas-masivas"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /curriculums/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_curriculum_configuration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"curriculums"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /delegados/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_delegation_period_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"delegados"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /retroalimentacion/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_feedback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"retroalimentacion"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operator/fields/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_field_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"fields"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /grados-medicos/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_health_degree_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grados-medicos"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /idiomas/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_language_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"idiomas"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operator/conjuntos-de-opciones/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_operator_namespace_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"conjuntos-de-opciones"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /perfeccionamiento-personal/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_personal_improvement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"perfeccionamiento-personal"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reconocimientos-personales/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_personal_recognition_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reconocimientos-personales"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /postitulos/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_postgraduate_degree_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"postitulos"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /experiencias-previas/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_previous_experience_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"experiencias-previas"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grados-academicos-y-titutos-profesionales/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_qualification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grados-academicos-y-titutos-profesionales"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /becas/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_scholarship_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"becas"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /redes-sociales/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_social_network_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"redes-sociales"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resumenes/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_summary_configuration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tags/crear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"crear"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operator/taxonomia/editar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_edit_taxonomy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"taxonomia"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operator/conjuntos-de-opciones/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_namespace_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"conjuntos-de-opciones"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /operator/conjuntos-de-opciones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_namespaces_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"conjuntos-de-opciones"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /operator/taxonomia/editar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const operator_update_taxonomy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"operator"],[2,[7,"/"],[2,[6,"taxonomia"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/activity_fields/:id/options(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const options_api_activity_field_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"activity_fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pa_api_base_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /perfeccionamiento-personal/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_improvement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"perfeccionamiento-personal"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /perfeccionamiento-personal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_improvements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"perfeccionamiento-personal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /personal_information_async(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_information_async_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"personal_information_async"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /mi-informacion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_information_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mi-informacion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /reconocimientos-personales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_recognition_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reconocimientos-personales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /reconocimientos-personales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_recognitions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reconocimientos-personales"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /postitulos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const postgraduate_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"postitulos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /postitulos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const postgraduate_degrees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"postitulos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /experiencias-previas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const previous_experience_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"experiencias-previas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /experiencias-previas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const previous_experiences_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"experiencias-previas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /titulos-profesionales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const professional_title_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"titulos-profesionales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /proyecto(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const proyect_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"proyecto"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /grados-academicos-y-titutos-profesionales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const qualifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grados-academicos-y-titutos-profesionales"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /recursos_de_apoyo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recursos_de_apoyo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recursos_de_apoyo"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/ejecutar-tareas/restart_sidekiq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restart_sidekiq_admin_task_executions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ejecutar-tareas"],[2,[7,"/"],[2,[6,"restart_sidekiq"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/:id/cargas-masivas/:id/reupload(.:format)
 * @param {any} id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reupload_bulk_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cargas-masivas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reupload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /becas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scholarship_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"becas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /becas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scholarships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"becas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /becas-y-reconocimientos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scholarships_and_recognitions_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"becas-y-reconocimientos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /actividades/show_async(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_async_activity_instances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[6,"show_async"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /show-curriculum-base(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_base_curriculum_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"show-curriculum-base"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /mi-resumen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_summary_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mi-resumen"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /redes-sociales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const social_network_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"redes-sociales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /redes-sociales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const social_networks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"redes-sociales"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/downloads/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_api_downloads_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /dejar-de-impersonar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_impersonate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dejar-de-impersonar"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resumenes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const summary_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"resumenes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resumenes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const summary_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resumenes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /taxonomia(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const taxonomy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"taxonomia"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resultados(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const taxonomy_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resultados"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /grados-academicos/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_academic_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grados-academicos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /grados-medicos/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_health_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"grados-medicos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /idiomas/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_language_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"idiomas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /perfeccionamiento-personal/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_personal_improvement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"perfeccionamiento-personal"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reconocimientos-personales/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_personal_recognition_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reconocimientos-personales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /postitulos/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_postgraduate_degree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"postitulos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /experiencias-previas/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_previous_experience_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"experiencias-previas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /titulos-profesionales/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_professional_title_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"titulos-profesionales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /becas/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_scholarship_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"becas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /redes-sociales/:id/toggle_hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_hide_social_network_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"redes-sociales"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /datos-en-clasificacion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unclassified_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"datos-en-clasificacion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /datos-en-clasificacion/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unclassified_datum_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"datos-en-clasificacion"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activity_uploads/:id/undraft_instance(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undraft_instance_activity_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activity_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"undraft_instance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mi-informacion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_personal_information_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mi-informacion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /actividades/:id/editar-etiquetas(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_tags_activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar-etiquetas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/taxonomia/editar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_taxonomy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"taxonomia"],[2,[7,"/"],[2,[6,"editar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /actividades/:id/editar-usuarios(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_users_activity_instance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"actividades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editar-usuarios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/usuarios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"usuarios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/usuarios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"usuarios"],[1,[2,[8,"."],[3,"format"]]]]]]]);



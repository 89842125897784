import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["input"];

    format(event) {
        const input = event.target;
        const value = input.value;

        if (this.shouldFormatAsRut(value)) {
            // Si la cadena debe ser tratada como RUT, aplicamos el formato
            const cleanInput = value.replace(/[^0-9kK]/g, ""); // Limpiamos caracteres no válidos
            const formattedRut = this.formatRut(cleanInput);
            input.value = formattedRut;
        } else {
            // Si no es un RUT, no hacemos nada (dejamos el texto intacto)
            input.value = value;
        }
    }

    shouldFormatAsRut(value) {
        // Verifica si el valor contiene solo números y opcionalmente "k" o es un RUT en construcción
        const hasOnlyRutCharacters = /^[0-9kK.\-]*$/.test(value); // Permitir números, puntos, guion, y "k"
        const hasAtLeastOneNumber = /\d/.test(value); // Verificar que contenga al menos un número

        // Solo formatear si contiene únicamente caracteres válidos de un RUT
        return hasOnlyRutCharacters && hasAtLeastOneNumber;
    }

    formatRut(value) {
        const length = value.length;

        if (length <= 1) return value; // Si es un solo carácter, no formatear

        const dv = value.slice(-1); // Último carácter es el dígito verificador
        const numbers = value.slice(0, -1); // Todos menos el dígito verificador

        // Inserta puntos cada tres dígitos desde el final
        const formattedNumbers = numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        // Devuelve el RUT con el guion y el dígito verificador
        return `${formattedNumbers}-${dv}`;
    }
}
